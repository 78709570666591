import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import ECommerceShipping from "./pages/Products/ECommerceShipping";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import OurAssociates from "./pages/ourassociates/OurAssociates";
import Services from "./pages/services/Services";
import TrackConsigment from "./pages/TrackConsigment";
import CourierTrack from "./pages/CourierTrack";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}



function App() {
  useEffect(() => {
    if (
      window.location.href === "https://ewcourier.com/lander/" ||
      window.location.href === "https://ewcourier.com/lander" ||
      window.location.href === "http://ewcourier.com/lander/" ||
      window.location.href === "http://ewcourier.com/lander"
    ) {
      window.location.replace("https://ewcourier.com/");
    }
  }, []);

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/OurAssociates" element={<OurAssociates />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/ECommerceShipping" element={<ECommerceShipping />} />
            <Route path="/consignment" element={<TrackConsigment />} />
            <Route path="/courier-track/:tracking_id" element={<CourierTrack />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
